import React from 'react'
import styles from './Table.module.css'
import {
  restructureResponse,
  generateEmptyResponse,
  aggregateResultsByFirstParameter,
} from '../../helpers/sparql'
import PublishUtils from './../../helpers/PublishUtils'
import { Icon } from './Icon'
import Command from '../../helpers/Command'

// import { Table as StrapTable } from 'reactstrap';



function camelCaseToSpaces(str) {


  str = str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase()
  str=str.replace("_"," ");
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const check = <Icon icon="check" color="green" />
const cross = <Icon icon="times" color="red" />
const crossDel = <Icon className={styles.deleteIcon +  " " + styles.link} icon="times" color="grey" />



class Table extends React.Component {

  getHeader(s)
  {
    try
    { 
      var json=JSON.parse(this.props.replaceHeaderJSON);
        var rs =json[s];
        if (rs!=null) return rs;
    }
    catch(e)
    {}
    return camelCaseToSpaces(s);
  }
  deleteSuccess=(arg)=>
  {
     console.log("success. we houd set a new timestamp parameter");
     PublishUtils.setTimeStampParameter(this,false);
     this.props.setLoading(false);
  }
  deleteFailure=(arg)=>
  {
    console.log("failure");
    window.alert("Deletion not succeeded");
    this.props.setLoading(false);
  }

deleteClick=(item) => {

  var rule=this.props.deletebuttonrule;
  if (rule==null) 
  {
    window.alert("this function is not available");
    return;
  }

  if (window.confirm("are you sure you want to delete this ?"))
  {
    var me=this;
    if ((rule.startsWith("http://")) || (rule.startsWith("https://")) )
    {
      this.props.setLoading(true);
      Command.ruleRule(rule,item,this.deleteSuccess,this.deleteFailure);
    }
    else
    {
   //  console.log(rule,item);
   this.props.setLoading(true);
      Command.runRulesWithTag(rule,item,this.deleteSuccess,this.deleteFailure);
    }
    
   
    
  }

}
shouldComponentUpdate(props, state,x)
{
  //var r=super.shouldComponentUpdate(props,state,x);
  if (props.data==this.props.data) 
  {
    if (this.props.highlightVariable== null)     return false;
    var par =this.props.highlightVariable;
    let selectedUri = this.props.pubsub[par];
    let selectedUri2 = props.pubsub[par];
    if (selectedUri==selectedUri2) return false;

  }
 
  if (this.props.resetSelectedValueWhenNotAvailable)
  {
    //not implemented. is seleteduri available?
   
    if (this.props.publishVariable != null) {
      let parameter = this.props.publishVariable
      let selectedUri = this.props.pubsub[parameter]
      if (selectedUri!=null)
      {
        // is reset? 
        if (selectedUri!=PublishUtils.resetUri)
        {
        // console.log("should reset value",selectedUri,props);
          this.props.publish(parameter,PublishUtils.resetUri);
          return false;
          
      
        }
      }
    }
  }
      
  
  return true;
}

  onClick = async uri => {

    if (uri==null) return;
    if (uri.length<2) return;

    if ((uri!=null) && (uri=="http://www.example.org/5c3c96ea1d83d8396b87bc0b") ) return;
   

    await PublishUtils.resetParameter(this)
    this.props.publish(this.props.publishVariable, uri)
  }
  openLink = (url) => {

  
    if (url==null) return;
    if (url.length<2) return;
    window.open(url, '_blank')
  }

  
  

  render() {
   
    var data = this.props.data;
    //if (data ==null) console.log("Table renderer heeft geen data",this.props.pubsub.datasets);
    if ( this.props.data==null || this.props.data.results==null || this.props.data.results.bindings==null ||this.props.data.results.bindings.length == 0) {
      if (this.props.renderEmpty) {
        data = generateEmptyResponse(this.props.query)
        // console.log("table rende empty ",this.props.query, this.props);
      } else {
        try {
          if (this.props.zeroResult != null) {
            if (
              this.props.zeroResult != null &&
              this.props.data.results &&
              this.props.data.results.bindings.length === 0
            ) {
              var label = this.props.zeroResult
              return (
                <div className={styles.main}>
                  <p>{label}</p>
                </div>
              )
            }
          }
        } catch (e) {}
        
        return null
      }
    }
   // console.log("rendering table not empty");

    let selectedUri = null
    
    if (this.props.highlightVariable != null) {
      let parameter = this.props.highlightVariable
      selectedUri = this.props.pubsub[parameter]
    }
   
    let headVars = data.head.vars
    let items = restructureResponse(data)

    if (this.props.aggregateResultsByID) {
      items = aggregateResultsByFirstParameter(items, headVars[0])
    }
    let center=styles.centertext;
   if (this.props.centerContent!=true) center="";


    var html = false
    if (this.props.ashtml) html = true
    var  deleteRow=false;
    if (this.props.deletebuttonrule!=null)
    {
      deleteRow=true;
    }
    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <table>
          <thead>
            <tr>
              {this.props.hasNumberedRows && (
                <th className={styles.rowNumberHeader +"  "+center} key={-1}></th>
              )}
              {headVars.map((headVar, index) => {
                if (headVar.endsWith('URI')) return null
                if (headVar.endsWith('URL')) return null
                if (headVar.endsWith('HIDDEN')) return null

                return <th className={center} key={index}>{this.getHeader(headVar)}</th>
              })}
                {deleteRow && (
                <th className={styles.rowNumberHeader +"  "+center} key={-2}>delete</th>
              )}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  {this.props.hasNumberedRows && (
                    <td className={styles.rowNumber+" "+center}>{index + 1}</td>
                  )}
                  {headVars.map((headVar, index) => {
                    if (headVar.endsWith('URI')) return null
                    if (headVar.endsWith('URL')) return null
                    if (headVar.endsWith('HIDDEN')) return null
                  

                    let value = item[headVar] || ''
                    let uri = item[headVar + 'URI']
                    let url = item[headVar + 'URL']

                    if (uri) {
                      let highlight = ''
                      if (uri === selectedUri) {
                        highlight = styles.highlight
                        // console.log("found a selection",highlight);
                      }

                      return (
                        <td 
                          key={index}
                          className={center}
                          
                        >
                          {html ? (
                            <div onClick={() => this.onClick(uri)} className={styles.link + ' ' + highlight+" "+styles.inlineblock}  dangerouslySetInnerHTML={{ __html: value }} />
                          ) : (
                            <div onClick={() => this.onClick(uri)} className={styles.link + ' ' + highlight+" "+styles.inlineblock} >{value}</div>
                            
                          )}
                        </td>
                      )
                    } else {
                      if (url) {
                        return (
                          <td
                            key={index}
                            className={center}
                          
                          >
                            {html ? (
                              <div className={styles.link+"  "+styles.inlineblock}
                              onClick={() => this.openLink(url)} dangerouslySetInnerHTML={{ __html: value }}
                              />
                            ) : (
                              <div   onClick={() => this.openLink(url)} className={styles.link+"  "+styles.inlineblock} >{value}</div>
                              
                            )}
                          </td>
                        )
                      } else {
                        let anders = null
                        if (value === 'true') anders = check
                        if (value === 'false') anders = cross
                        if (anders != null) {
                          return (
                            <td key={index} className={styles.iconcenter+" "+center}>
                              {anders}
                            </td>
                          )
                        }

                        return (
                          <td key={index} className={center}>
                            {html ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: value }}
                              />
                            ) : (
                              value
                            )}
                          </td>
                        )
                      }
                    }

                  })}

{deleteRow && (
                   <td onClick={() => this.deleteClick(item)} key={index} className={styles.iconcenter+" "+center}>
                   {crossDel}
                 </td>
                  )}




                </tr>
              )
            }


            
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Table

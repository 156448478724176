import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { FormGroup as _FormGroup, Input as _Input, Label as _Label } from 'reactstrap'
import { useDebounce } from '../../hooks/use-debounce'
import PublishUtils from '../../helpers/PublishUtils'
import { setState } from 'react-jsonschema-form/lib/utils'

// Workaround due to incorrect typing of the class-component instances
const FormGroup = _FormGroup as any
const Input = _Input as any
const Label = _Label as any

const DEFAULT = {
  DEBOUNCE_TIME: 1, //second
  WIDTH: '100%',
}

export const textInputWidgetDescription = {
  name: 'Text input',
  component: TextInput,
  label: 'Text input',
  variables: [
    {
      name: 'label',
      type: 'text',
      label: 'Label above input-field',
    },
    {
      name: 'topicToPublish',
      type: 'text',
      label:
        'Topic/Parameter to which to publish the user input',
    },
    {
      name: 'inputParameter',
      type: 'text',
      label:
        'Initial value  (refresh is necessary)',
    },
    {
      name: 'debounceTimeInSeconds',
      type: 'text',
      label: 'Seconds to wait after user input to broadcast value to topic',
    },
    {
      name: 'width',
      type: 'text',
      label: 'Width of text input (use css length value; default is "100%")',
    }
  ],
}

export function TextInput(props: {
  label?: string
  topicToPublish?: string
  inputParameter?:string
  debounceTimeInSeconds: string
  width: string
  pubsub?:any
  publish: (topic: string, value: string) => void
}) {



    const publishText = (text: string) => {
    if (props.topicToPublish) {
      //props.publish(props.topicToPublish, text)
      //console.log(PublishUtils.processStringForParameters({props:props},text));
       var resolvedText2=PublishUtils.processStringForParameters({props:props},text);
       setResolvedText(resolvedText2);
       setText(text);
      props.publish(props.topicToPublish,resolvedText2)
    }
  }

   
  
 // console.log(props.debounceTimeInSeconds, typeof props.debounceTimeInSeconds)
  const parsedDebounceTime = Number(props.debounceTimeInSeconds || DEFAULT.DEBOUNCE_TIME) * 1000
var initialValue="";
var initialResolvedValue="";
var publishFirstTime=false;
  if (props.inputParameter)
    {
     
      initialValue = props.inputParameter
      initialResolvedValue=PublishUtils.processStringForParameters({props:props},initialValue);
     publishFirstTime =true;
     
    }
   
 const [text, setText] = useState(initialValue);
 const [resolvedText, setResolvedText] = useState(initialResolvedValue)
 
 
  

  useDebounce(() => publishText(text), parsedDebounceTime, [text])
  useEffect(() =>  publishText(text), [props.topicToPublish])  // eslint-disable-line react-hooks/exhaustive-deps
 

 useEffect(() => {


  

  var rt=PublishUtils.processStringForParameters({props:props},text);
  if (rt!=resolvedText) {
    console.log("updating to ",rt);
  
    publishText(text)
  } else {
  //  setShouldUpdate(false);
  }
}, [props]);

 //if (publishFirstTime) publishText(initialValue);
if (Number.isNaN(parsedDebounceTime))
  return <div>Error: configured waiting time is not a number: "{props.debounceTimeInSeconds}"</div>



  return (
    <FormGroup style={{padding: '0.25rem', width: '100%'}}>
      {props.label &&
        <Label style={{marginLeft: '0.25rem', marginBottom: '0.25rem'}}>{props.label}</Label>
      }
      <Input hiddenvalue={{resolvedText}} value={text}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        style={{width: props.width || DEFAULT.WIDTH}}
      />
    </FormGroup> as ReactNode
  )
}

import { lazy } from 'react';

import PublishUtils from './../../helpers/PublishUtils'

export default class TableDescriptor {
    static getDescription() {
        return {
          name: 'Table',
          status:"normal",
          category:"Default",
          component: lazy(() =>  import('./Table')), 
          label: 'Table widget',
          variables: [
            {
              name: 'query',
              type: 'yasgui',

              description:"extra documentation",
              defaultQuery:"PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\rselect distinct ?label ?labelURI ?comment\n\rwhere\n\r{\n\r    graph ?g\n\r  {\n\r    ?labelURI a/rdfs:subClassOf* <{{cls}}>. ?labelURI rdfs:label ?label.\n\r      optional { ?labelURI rdfs:comment ?comment}\n\r     \n\r  }\n\r\n\r}\n\r        limit 100",
              label:
                'Query to fill table. If table item should be a link, than the you should add a property with the same name with suffix "URI", eg: ?object ?objectURI.',
            },
            {
              name: 'publishVariable',
              type: 'text',
              label:
                'Variabele in which item URI is published, when a link in the table is clicked.',
                description:"more information about the publish variable"
            },
            {
              name: 'highlightVariable',
              type: 'text',
              label: 'highlight table row with this uri',
            },
            {
              name: 'ashtml',
              type: 'boolean',
              label: 'tabel waardes als html',
            },
            {
              name: 'title',
              type: 'text',
              label: 'Titel boven de tabel',
            },
            {
              name: 'renderEmpty',
              type: 'boolean',
              label: 'render an empty table',
            },
            {
              name: 'zeroResult',
              type: 'text',
              label:
                'text to display when sparql results is valid but has zero results',
            },
            {
              name: 'aggregateResultsByFirstParameter',
              type: 'boolean',
              label: 'aggregate sparql results by the first parameter',
            },
            {
              name: 'replaceHeaderJSON',
              type: 'text',
              label:
                'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
            },
            {
              name: 'resetSelectedValueWhenNotAvailable',
              type: 'boolean',
              label: 'reset selected value when it is not available (anymore)',
            },
            {
              name: 'hasNumberedRows',
              type: 'boolean',
              label: 'show row numbers',
            },
            {
              name: 'centerContent',
              type: 'boolean',
              label: 'center values in the tabel',
            },
            PublishUtils.getResetValueDef(),
            {
                name: 'deletebuttonrule',
                type: 'text',
                label: 'uri or tag for rule when pushing delete icon',
              },
              PublishUtils.getTimestampParametersDef(),
          ],
        }
      }
    
}
class PublishUtils {
  static resetUri="http://www.buildingbits.nl/reset";
  
  static setTimeStampParameter(widget, debug) 
  {
    if (debug) {
      console.log(
        'timestamp parameter called ',
        widget.props.timestampParameters
      )
    }
    if (widget.props.timestampParameters) {
      var valuesRaw = widget.props.timestampParameters
      var values = valuesRaw.split(',')
      var me = widget
      var value = 'time:' + Date.now()
      if (debug) console.log(values, value)

      if (values.length > 0) {
        if (debug) console.log(values[0],widget.props.pubsub[values[0]])
        if (
         // widget.props.pubsub[values[0]] != null &&
          widget.props.pubsub[values[0]] !== value
        ) {
          //console.log(widget);
          setTimeout(function () {
           // console.log('resetting parameter 1 ' + values[0], value)
            widget.props.publish(values[0], value)
          }, 25)
        }
        //   data[values[n]]="http://www.buildingbits.nl/reset";
      }
      if (values.length > 1) {
        if (
          widget.props.pubsub[values[1]] != null &&
          widget.props.pubsub[values[1]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 2 ' + values[1])
            me.props.publish(values[1], value)
          }, 50)
        }
      }
      if (values.length > 2) {
        if (
          widget.props.pubsub[values[2]] != null &&
          widget.props.pubsub[values[2]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 3 ' + values[2])
            me.props.publish(values[2], value)
          }, 75)
        }
      }
      if (values.length > 3) {
        if (
          widget.props.pubsub[values[3]] != null &&
          widget.props.pubsub[values[3]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 4 ' + values[3])
            me.props.publish(values[3], value)
          }, 100)
        }
      }
      if (values.length > 4) {
        if (
          widget.props.pubsub[values[4]] != null &&
          widget.props.pubsub[values[4]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 5 ' + values[4])
            me.props.publish(values[4], value)
          }, 125)
        }
      }
      if (values.length > 5) {
        console.log('too many properties to reset')
        alert('too many properties to reset in search box')
      }

      if (debug) {
        console.log('reset parameter called for ', values)
      }
    }
  }

static publish(props,key,value)
{
  var data={   key:value  };

  props.dispatch({
    type: 'PUBLISH',
    data: data
  });
  
}




  static getResetValueDef() {
    return {
      name: 'resetValues',
      type: 'text',
      label:
        'reset this parameter after select event. multiple parameters via comma seperation',
    }
  }

  static getTimestampParametersDef() {
    return {
      name: 'timestampParameters',
      type: 'text',
      label:
        'sets a timestamp after main event. multiple parameters via comma seperation',
    }
  }

  /**
   * @param widget
   * @param debug {boolean}
   * @return {Promise<void>}
   */
  static resetParameter(widget, debug) {
    if (debug) {
      console.log('reset parameter called', widget.props.resetValues)
    }
    if (!widget.props.resetValues) return

    const resetParametersRaw = widget.props.resetValues
    const resetParameters = resetParametersRaw.split(',')
    const resetValue = 'http://www.buildingbits.nl/reset'

    const promises = []
    for (const resetParameter of resetParameters) {
      const currentValue = widget.props.pubsub[resetParameter]
      if (currentValue == null || currentValue === resetValue) continue
      promises.push(setTimeoutAsync(() => {
        console.log(`resetting parameter: ${resetParameter}`)
        widget.props.publish(resetParameter, resetValue)
      }, 25))
    }

    return Promise.all(promises)
  }

  static processStringForParameters(widget, strTemplate) {
    
    try
    {
    let query = strTemplate
    if (!query) {
      return
    }
    //const regex = /{{\S*}}/g;
    const regex = /{{!?[A-Za-z0-9|:./]*}}/g
    let subscribeProps = query.match(regex)

    if (subscribeProps == null) return strTemplate
    subscribeProps = subscribeProps.map((f) => f.replace(/[{}]/g, ''))
    subscribeProps = [...new Set(subscribeProps)] // remove duplicates

    for (var i = 0; i < subscribeProps.length; i++) {
      let subscribeProp = subscribeProps[i]
      let subscribeValue = widget.props.pubsub[subscribeProp]
      //console.log(query,subscribeProps,subscribeValue,widget.props);

      if (subscribeValue == null) {
        // console.log("found empty property or composed properties",subscribeProp);
      }

      if (subscribeProp.includes('||')) {
        let subscribeProp2 = subscribeProp.split('||')[0]
        subscribeValue = widget.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }

        //  console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          var prop = subscribeProp.split('||')[1].split('||')[0].split('::')[0]
          subscribeValue = widget.props.pubsub[prop]
          if (subscribeValue === 'http://www.buildingbits.nl/reset') {
            subscribeValue = null
          }
        }
      }

      if (subscribeProp.includes('::') && subscribeValue == null) {
        let subscribeProp2 = subscribeProp.split('::')[0]
        subscribeValue = widget.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }
        // console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          subscribeValue = subscribeProp.split('::')[1]
        }
        //  console.log(subscribeProp,subscribeValue);
      }

      const negationPattern = /^!(.+)$/
      const negationMatch = subscribeProp.match(negationPattern)
      if (negationMatch) {
        const negatedSubscribeProp = negationMatch[1]
        const value = widget.props.pubsub[negatedSubscribeProp]
        const booleanValue = String(value).toLowerCase() === 'true'
        subscribeValue = !booleanValue
      }

      subscribeProp = subscribeProp.replaceAll('||', '\\|\\|')
      let regex2 = new RegExp(`{{${subscribeProp}}}`, 'g')
      // var o={q:query};

      //console.log("changing query ",query,regex2,subscribeValue);
      try {
        if (subscribeValue == null) subscribeValue = ''
        query = query.replace(regex2, subscribeValue)
        // console.log("results ",query);
      } catch (e) {
        console.log(
          'ERROR ',
          query,
          subscribeValue,
          subscribeProps,
          widget.props.pubsub[subscribeProp]
        )
      }
      // o.q2=query;
      //console.log(o,subscribeProp);
    }
    return query
  }
  catch(e)
  {console.log(e);}
  return strTemplate;
  }

  static emptyDataParameterChange(widget, arg, arg2) {
    if (widget.props.resetDataProps) {
      if (arg.data != null) {
        console.log(widget.props.pubsub.button, arg.pubsub.button)
        console.log(widget, arg, arg2)
      }
    }
  }

  static getEmptyDataParameterChange() {
    return {
      name: 'resetDataProps',
      type: 'text',
      label:
        'reset data value when this parameter changes. multiple parameters via comma seperation',
    }
  }

  /**
   * Converts a list of values to a single value string according to multipleselectparametertype
   * @param multipleselectparametertype {"csvuri" | "csvstring" | "valuestring" | "valueuri"}
   * @param list {Array<string | number>}
   * @returns {string}
   */
  static getListAsSingleValue(multipleselectparametertype, list) {
    const record = {}
    list.forEach(item => record[item] = true)
    return PublishUtils.getMultiplePropValueAsSingleValue(multipleselectparametertype, record)
  }

  /**
   * Converts a record holding multiple values to a single value string according to multipleselectparametertype
   * @param multipleselectparametertype {"csvuri" | "csvstring" | "valuestring" | "valueuri"}
   * @param values {Record<string, boolean>} a record where key indicates the value to convert, and the value indicating whether to include it
   * @returns {string}
   */
  static getMultiplePropValueAsSingleValue(multipleselectparametertype,values)
  {
    let valueString = ''
      let komma = ''
      let pre = '<'
      let end = '>'
      let kommaT = ','
      if (multipleselectparametertype === 'csvstring') {
        pre = '"'
        end = pre
      }
      if (multipleselectparametertype === 'valuestring') {
        pre = '("'
        end = '") '
        kommaT = ''
      }
      if (multipleselectparametertype === 'valueuri') {
        pre = '(<'
        end = '>) '
        kommaT = ''
      }
    if (values==null)
    {
      return this.getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype);
    }
      for (let n in values) {
        if (values[n]) {
          valueString += komma + pre + n + end
          komma = kommaT
        }
      }
      //  console.log(valueString);
      if (valueString === '') valueString=this.getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype);
      return valueString;
  }

  /**
   * Parses multiple values from multiplePropValue according to multipleSelectParameterType
   * @param multipleSelectParameterType {"csvuri" | "csvstring" | "valuestring" | "valueuri"}
   * @param multiplePropValue {string} a string which is formatted contain multiple values
   * @returns {string[]}
   */
  static parseMultiplePropValue(multipleSelectParameterType, multiplePropValue) {

    const escapeChar = '\\'

    const formatDict = {
      csvuri: { prefix: '<', postfix: '>', separator: ',' },
      csvstring: { prefix: '"', postfix: '"', separator: ',' },
      valuestring: { prefix: '("', postfix: '")', separator: ' ' },
      valueuri: { prefix: '(<', postfix: '>)', separator: ' ' },
    }

    const { prefix, postfix, separator } = formatDict[multipleSelectParameterType]

    /** 
     * @param lookingFor {'prefix' | 'value' | 'postfix' | 'separator'}
     * @returns {'prefix' | 'value' | 'postfix' | 'separator'}
    */
    const after = (lookingFor) => {
      if (lookingFor === 'prefix') return 'value'
      if (lookingFor === 'value' && postfix === '') return 'separator'
      if (lookingFor === 'value') return 'postfix'
      if (lookingFor === 'postfix' && separator === '') return 'prefix'
      if (lookingFor === 'postfix') return 'separator'
      if (lookingFor === 'separator' && prefix === '') return 'value'
      if (lookingFor === 'separator') return 'prefix'
      throw new Error(`unknown value for "lookingFor": ${lookingFor}`)
    }

    /**
     * parses char as part of symbol and pushes that onto parsed
     * @param {string} parsed
     * @param {string} char
     * @param {string} symbol
     * @returns {string}
     */
    const parseSymbolIntoParsed = (parsed, char, symbol) => {
        const expectedChar = symbol[parsed.length]
        if (char !== expectedChar) {
          console.log({char, expectedChar, symbol, parsedLength: parsed.length})
          throw new Error(`${char} was expected to be part of ${symbol} as ${multipleSelectParameterType} in ${multiplePropValue}`)
        }
        parsed += char
        return parsed
    }

    /** @type {{ currentEscapeChar?: string, values: string[], parsed: string, lookingFor: 'prefix' | 'value' | 'postfix' | 'separator' }} */
    const parserState = { values: [], parsed: '', lookingFor: after('separator') }
    const { values } = Array.from(multiplePropValue).reduce(({ currentEscapeChar, values, parsed, lookingFor }, char) => {
      try {

        if (lookingFor === 'prefix') {
          parsed = parseSymbolIntoParsed(parsed, char, prefix)
          if (parsed.length === prefix.length) return { values, parsed: '', lookingFor: after('prefix') }
          return { values, parsed, lookingFor }
        }

        if (lookingFor === 'value') {
          if (!currentEscapeChar && char === escapeChar)
            return { currentEscapeChar: char, values, parsed, lookingFor }

          if (currentEscapeChar || char !== postfix[0]) {
            parsed += char
            return { values, parsed, lookingFor }
          }

          values.push(parsed)
          parsed = ''
          lookingFor = after('value')
        }

        if (lookingFor === 'postfix') {
          parsed = parseSymbolIntoParsed(parsed, char, postfix)
          if (parsed.length === postfix.length) return { values, parsed: '', lookingFor: after('postfix') }
          return { values, parsed, lookingFor }
        }

        if (lookingFor === 'separator') {
          parsed = parseSymbolIntoParsed(parsed, char, separator)
          if (parsed.length === separator.length) return { values, parsed: '', lookingFor: after('separator') }
          return { values, parsed, lookingFor }
        }

      } catch(e) {
        throw new Error(e.message + '\n\n' + JSON.stringify({ values, parsed, lookingFor, char }))
      }

      throw new Error(`unknown value for "lookingFor": ${lookingFor}`)
    }, parserState)

    return values
  }

  static getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype)
  {
    let valueString = ''
     let value='';
      let pre = '<'
      let end = '>'
      let kommaT = ','
      if (multipleselectparametertype === 'csvstring') {
        pre = '"'
        end = pre
      }
      if (multipleselectparametertype === 'valuestring') {
        pre = '("'
        end = '") '
        kommaT = ''
      }
      if (multipleselectparametertype === 'valueuri') {
        pre = '(<'
        end = '>) '
        kommaT = ''
        value='http://www.buildingbits.nl/reset';
      }

              
          valueString = pre + value + end
         
      
      //  console.log(valueString);
    //  if (valueString === '') valueString = '<http://www.buildingbits.nl/reset>';
      return valueString;
  }


  static getMultipleValueFormatOptions({
    name = 'multipleselectparametertype',
    label = 'for Multiple selection only: how to set the multivalue parameters'
  } = {})
  {
    return  {
      name,
      type: 'select',
      options: [
        { label: 'csv as uri', value: 'csvuri' },
        { label: 'csv as string with "', value: 'csvstring' },
        { label: 'sparql value string with "', value: 'valuestring' },
        { label: 'sparql value uri)', value: 'valueuri' },
      ],
      label
    }
  }
}

/**
 * @template T
 * @param callback {() => T}
 * @param delay {number}
 * @return {Promise<T>}
 */
function setTimeoutAsync(callback, delay) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(callback())
      } catch (e) {
        reject(e)
      }
    }, delay)
  })
}

export default PublishUtils
